const monsterStatsbyCR = [
 {
   "cr": 0.5,
   "hp": 10,
   "ac": 11,
   "highAttack": 1,
   "lowAttack": 0,
   "avgDmgHigh": 4,
   "avgDmgLow": 3,
   "primaryAbilityDc": 11,
   "secondaryAbilityDc": 8,
   "goodSave": 3,
   "poorSave": 0
 },
 {
   "cr": 1,
   "hp": 15,
   "ac": 12,
   "highAttack": 2,
   "lowAttack": 1,
   "avgDmgHigh": 7,
   "avgDmgLow": 5,
   "primaryAbilityDc": 12,
   "secondaryAbilityDc": 9,
   "goodSave": 4,
   "poorSave": 1
 },
 {
   "cr": 2,
   "hp": 20,
   "ac": 14,
   "highAttack": 4,
   "lowAttack": 3,
   "avgDmgHigh": 10,
   "avgDmgLow": 7,
   "primaryAbilityDc": 13,
   "secondaryAbilityDc": 9,
   "goodSave": 5,
   "poorSave": 1
 },
 {
   "cr": 3,
   "hp": 30,
   "ac": 15,
   "highAttack": 6,
   "lowAttack": 4,
   "avgDmgHigh": 13,
   "avgDmgLow": 9,
   "primaryAbilityDc": 14,
   "secondaryAbilityDc": 10,
   "goodSave": 6,
   "poorSave": 2
 },
 {
   "cr": 4,
   "hp": 40,
   "ac": 17,
   "highAttack": 8,
   "lowAttack": 6,
   "avgDmgHigh": 16,
   "avgDmgLow": 12,
   "primaryAbilityDc": 15,
   "secondaryAbilityDc": 10,
   "goodSave": 7,
   "poorSave": 3
 },
 {
   "cr": 5,
   "hp": 55,
   "ac": 18,
   "highAttack": 10,
   "lowAttack": 7,
   "avgDmgHigh": 20,
   "avgDmgLow": 15,
   "primaryAbilityDc": 15,
   "secondaryAbilityDc": 11,
   "goodSave": 8,
   "poorSave": 4
 },
 {
   "cr": 6,
   "hp": 70,
   "ac": 19,
   "highAttack": 12,
   "lowAttack": 8,
   "avgDmgHigh": 25,
   "avgDmgLow": 18,
   "primaryAbilityDc": 16,
   "secondaryAbilityDc": 11,
   "goodSave": 9,
   "poorSave": 5
 },
 {
   "cr": 7,
   "hp": 85,
   "ac": 20,
   "highAttack": 13,
   "lowAttack": 10,
   "avgDmgHigh": 30,
   "avgDmgLow": 22,
   "primaryAbilityDc": 17,
   "secondaryAbilityDc": 12,
   "goodSave": 10,
   "poorSave": 6
 },
 {
   "cr": 8,
   "hp": 100,
   "ac": 21,
   "highAttack": 15,
   "lowAttack": 11,
   "avgDmgHigh": 35,
   "avgDmgLow": 26,
   "primaryAbilityDc": 18,
   "secondaryAbilityDc": 12,
   "goodSave": 11,
   "poorSave": 7
 },
 {
   "cr": 9,
   "hp": 115,
   "ac": 23,
   "highAttack": 17,
   "lowAttack": 12,
   "avgDmgHigh": 40,
   "avgDmgLow": 30,
   "primaryAbilityDc": 18,
   "secondaryAbilityDc": 13,
   "goodSave": 12,
   "poorSave": 8
 },
 {
   "cr": 10,
   "hp": 130,
   "ac": 24,
   "highAttack": 18,
   "lowAttack": 13,
   "avgDmgHigh": 45,
   "avgDmgLow": 33,
   "primaryAbilityDc": 19,
   "secondaryAbilityDc": 13,
   "goodSave": 13,
   "poorSave": 9
 },
 {
   "cr": 11,
   "hp": 145,
   "ac": 25,
   "highAttack": 19,
   "lowAttack": 14,
   "avgDmgHigh": 50,
   "avgDmgLow": 37,
   "primaryAbilityDc": 20,
   "secondaryAbilityDc": 14,
   "goodSave": 14,
   "poorSave": 10
 },
 {
   "cr": 12,
   "hp": 160,
   "ac": 27,
   "highAttack": 21,
   "lowAttack": 15,
   "avgDmgHigh": 55,
   "avgDmgLow": 41,
   "primaryAbilityDc": 21,
   "secondaryAbilityDc": 15,
   "goodSave": 15,
   "poorSave": 11
 },
 {
   "cr": 13,
   "hp": 180,
   "ac": 28,
   "highAttack": 22,
   "lowAttack": 16,
   "avgDmgHigh": 60,
   "avgDmgLow": 45,
   "primaryAbilityDc": 21,
   "secondaryAbilityDc": 15,
   "goodSave": 16,
   "poorSave": 12
 },
 {
   "cr": 14,
   "hp": 200,
   "ac": 29,
   "highAttack": 23,
   "lowAttack": 17,
   "avgDmgHigh": 65,
   "avgDmgLow": 48,
   "primaryAbilityDc": 22,
   "secondaryAbilityDc": 16,
   "goodSave": 17,
   "poorSave": 12
 },
 {
   "cr": 15,
   "hp": 220,
   "ac": 30,
   "highAttack": 24,
   "lowAttack": 18,
   "avgDmgHigh": 70,
   "avgDmgLow": 52,
   "primaryAbilityDc": 23,
   "secondaryAbilityDc": 16,
   "goodSave": 18,
   "poorSave": 13
 },
 {
   "cr": 16,
   "hp": 240,
   "ac": 31,
   "highAttack": 26,
   "lowAttack": 19,
   "avgDmgHigh": 80,
   "avgDmgLow": 60,
   "primaryAbilityDc": 24,
   "secondaryAbilityDc": 17,
   "goodSave": 19,
   "poorSave": 14
 },
 {
   "cr": 17,
   "hp": 270,
   "ac": 32,
   "highAttack": 27,
   "lowAttack": 20,
   "avgDmgHigh": 90,
   "avgDmgLow": 67,
   "primaryAbilityDc": 24,
   "secondaryAbilityDc": 18,
   "goodSave": 20,
   "poorSave": 15
 },
 {
   "cr": 18,
   "hp": 300,
   "ac": 33,
   "highAttack": 28,
   "lowAttack": 21,
   "avgDmgHigh": 100,
   "avgDmgLow": 75,
   "primaryAbilityDc": 25,
   "secondaryAbilityDc": 18,
   "goodSave": 20,
   "poorSave": 16
 },
 {
   "cr": 19,
   "hp": 330,
   "ac": 34,
   "highAttack": 29,
   "lowAttack": 22,
   "avgDmgHigh": 110,
   "avgDmgLow": 82,
   "primaryAbilityDc": 26,
   "secondaryAbilityDc": 19,
   "goodSave": 21,
   "poorSave": 16
 },
 {
   "cr": 20,
   "hp": 370,
   "ac": 36,
   "highAttack": 30,
   "lowAttack": 23,
   "avgDmgHigh": 120,
   "avgDmgLow": 90,
   "primaryAbilityDc": 27,
   "secondaryAbilityDc": 20,
   "goodSave": 22,
   "poorSave": 17
 },
 {
   "cr": 21,
   "hp": 400,
   "ac": 37,
   "highAttack": 31,
   "lowAttack": 24,
   "avgDmgHigh": 130,
   "avgDmgLow": 98,
   "primaryAbilityDc": 27,
   "secondaryAbilityDc": 20,
   "goodSave": 23,
   "poorSave": 18
 },
 {
   "cr": 22,
   "hp": 440,
   "ac": 39,
   "highAttack": 32,
   "lowAttack": 25,
   "avgDmgHigh": 140,
   "avgDmgLow": 105,
   "primaryAbilityDc": 28,
   "secondaryAbilityDc": 21,
   "goodSave": 23,
   "poorSave": 18
 },
 {
   "cr": 23,
   "hp": 480,
   "ac": 40,
   "highAttack": 33,
   "lowAttack": 26,
   "avgDmgHigh": 150,
   "avgDmgLow": 113,
   "primaryAbilityDc": 29,
   "secondaryAbilityDc": 22,
   "goodSave": 24,
   "poorSave": 19
 },
 {
   "cr": 24,
   "hp": 520,
   "ac": 42,
   "highAttack": 35,
   "lowAttack": 27,
   "avgDmgHigh": 165,
   "avgDmgLow": 124,
   "primaryAbilityDc": 30,
   "secondaryAbilityDc": 23,
   "goodSave": 25,
   "poorSave": 20
 },
 {
   "cr": 25,
   "hp": 560,
   "ac": 43,
   "highAttack": 36,
   "lowAttack": 28,
   "avgDmgHigh": 180,
   "avgDmgLow": 135,
   "primaryAbilityDc": 30,
   "secondaryAbilityDc": 24,
   "goodSave": 26,
   "poorSave": 21
 },
 {
   "cr": 26,
   "hp": 600,
   "ac": 44,
   "highAttack": 37,
   "lowAttack": 29,
   "avgDmgHigh": 195,
   "avgDmgLow": 145,
   "primaryAbilityDc": 31,
   "secondaryAbilityDc": 25,
   "goodSave": 27,
   "poorSave": 22
 },
 {
   "cr": 27,
   "hp": 640,
   "ac": 45,
   "highAttack": 38,
   "lowAttack": 30,
   "avgDmgHigh": 210,
   "avgDmgLow": 155,
   "primaryAbilityDc": 32,
   "secondaryAbilityDc": 26,
   "goodSave": 28,
   "poorSave": 23
 },
 {
   "cr": 28,
   "hp": 680,
   "ac": 46,
   "highAttack": 39,
   "lowAttack": 31,
   "avgDmgHigh": 225,
   "avgDmgLow": 165,
   "primaryAbilityDc": 33,
   "secondaryAbilityDc": 27,
   "goodSave": 29,
   "poorSave": 24
 },
 {
   "cr": 29,
   "hp": 720,
   "ac": 47,
   "highAttack": 40,
   "lowAttack": 32,
   "avgDmgHigh": 240,
   "avgDmgLow": 175,
   "primaryAbilityDc": 34,
   "secondaryAbilityDc": 28,
   "goodSave": 30,
   "poorSave": 25
 },
 {
   "cr": 30,
   "hp": 760,
   "ac": 48,
   "highAttack": 41,
   "lowAttack": 33,
   "avgDmgHigh": 255,
   "avgDmgLow": 185,
   "primaryAbilityDc": 35,
   "secondaryAbilityDc": 29,
   "goodSave": 31,
   "poorSave": 26
 }
];
export default monsterStatsbyCR.reverse();