import React from 'react'
const MonsterOptions = [
    <option>Aasimar</option>,
    <option>Abaia</option>,
    <option>Aboleth</option>,
    <option>Achaierai</option>,
    <option>Adaro</option>,
    <option>Adherer</option>,
    <option>Adlet</option>,
    <option>Aeon, Akhana</option>,
    <option>Aeon, Bythos</option>,
    <option>Aeon, Paracletus</option>,
    <option>Aeon, Pleroma</option>,
    <option>Aeon, Theletos</option>,
    <option>Agathion, Avoral</option>,
    <option>Agathion, Cetaceal</option>,
    <option>Agathion, Draconal</option>,
    <option>Agathion, Leonal</option>,
    <option>Agathion, Silvanshee</option>,
    <option>Agathion, Vulpinal</option>,
    <option>Ahuizotl</option>,
    <option>Akata</option>,
    <option>Akhlut</option>,
    <option>Allip</option>,
    <option>Almiraj</option>,
    <option>Alpluachra</option>,
    <option>Alraune</option>,
    <option>Amoeba, Giant</option>,
    <option>Amphisbaena</option>,
    <option>Angel, Astral Deva</option>,
    <option>Angel, Cassisian</option>,
    <option>Angel, Monadic Deva</option>,
    <option>Angel, Movanic Deva</option>,
    <option>Angel, Planetar</option>,
    <option>Angel, Solar</option>,
    <option>Animal Lord</option>,
    <option>Animate Dream</option>,
    <option>Animated Object</option>,
    <option>Ankheg</option>,
    <option>Ankou</option>,
    <option>Annis Hag</option>,
    <option>Ant, Giant</option>,
    <option>Aoandon</option>,
    <option>Ape, Dire</option>,
    <option>Ape, Gorilla</option>,
    <option>Apocalypse Locust</option>,
    <option>Aranea</option>,
    <option>Archaeopteryx</option>,
    <option>Archon, Harbinger</option>,
    <option>Archon, Hound</option>,
    <option>Archon, Lantern</option>,
    <option>Archon, Legion</option>,
    <option>Archon, Shield</option>,
    <option>Archon, Star</option>,
    <option>Archon, Trumpet</option>,
    <option>Argus</option>,
    <option>Ascomoid</option>,
    <option>Assassin Vine</option>,
    <option>Astral Leviathan</option>,
    <option>Asura, Adhukait</option>,
    <option>Asura, Aghasura</option>,
    <option>Asura, Asurendra</option>,
    <option>Asura, Tripurasura</option>,
    <option>Asura, Upasunda</option>,
    <option>Athach</option>,
    <option>Atomie</option>,
    <option>Attic Whisperer</option>,
    <option>Aurumvorax</option>,
    <option>Axe Beak</option>,
    <option>Axiomite</option>,
    <option>Azata, Bralani</option>,
    <option>Azata, Brijidine</option>,
    <option>Azata, Ghaele</option>,
    <option>Azata, Lillend</option>,
    <option>Azata, Lyrakien</option>,
    <option>Azer</option>,
    <option>Azruverda</option>,
    <option>Badger</option>,
    <option>Badger, Dire</option>,
    <option>Bakekujira</option>,
    <option>Baku</option>,
    <option>Bandersnatch</option>,
    <option>Banshee</option>,
    <option>Baregara</option>,
    <option>Barghest</option>,
    <option>Barghest, Greater</option>,
    <option>Barometz</option>,
    <option>Basidirond</option>,
    <option>Basilisk</option>,
    <option>Bat, Dire</option>,
    <option>Bat, Mobat</option>,
    <option>Bat, Skaveling</option>,
    <option>Baykok</option>,
    <option>Bear, Dire</option>,
    <option>Bear, Grizzly</option>,
    <option>Bebilith</option>,
    <option>Bee, Giant</option>,
    <option>Beetle, Fire</option>,
    <option>Beetle, Giant Stag</option>,
    <option>Beetle, Goliath Stag</option>,
    <option>Beetle, Slicer</option>,
    <option>Beheaded</option>,
    <option>Behemoth, Tempest</option>,
    <option>Behemoth, Thalassic</option>,
    <option>Behemoth, Thunder</option>,
    <option>Behir</option>,
    <option>Belker</option>,
    <option>Berbalang</option>,
    <option>Bhole</option>,
    <option>Bhuta</option>,
    <option>Black Pudding</option>,
    <option>Blindheim</option>,
    <option>Blink Dog</option>,
    <option>Blood Hag</option>,
    <option>Boar</option>,
    <option>Boar, Dire</option>,
    <option>Bodak</option>,
    <option>Bodythief</option>,
    <option>Bogeyman</option>,
    <option>Boggard</option>,
    <option>Boilborn</option>,
    <option>Bokrug</option>,
    <option>Brain Ooze</option>,
    <option>Brethedan</option>,
    <option>Broken Soul</option>,
    <option>Brownie</option>,
    <option>Bugbear</option>,
    <option>Buggane</option>,
    <option>Bulette</option>,
    <option>Bunyip</option>,
    <option>Carbuncle</option>,
    <option>Carnivorous Blob</option>,
    <option>Carnivorous Crystal</option>,
    <option>Caryatid Column</option>,
    <option>Cat, Cheetah</option>,
    <option>Cat, Leopard</option>,
    <option>Catfolk</option>,
    <option>Catoblepas</option>,
    <option>Caulborn</option>,
    <option>Cave Fisher</option>,
    <option>Cecaelia</option>,
    <option>Centaur</option>,
    <option>Centipede, Giant</option>,
    <option>Centipede, Giant Whiptail</option>,
    <option>Centipede, Titan</option>,
    <option>Cephalophore</option>,
    <option>Ceratioidi</option>,
    <option>Cerberi</option>,
    <option>Cerebric Fungus</option>,
    <option>Chaneque</option>,
    <option>Changeling</option>,
    <option>Chaos Beast</option>,
    <option>Charda</option>,
    <option>Charybdis</option>,
    <option>Chimera</option>,
    <option>Choker</option>,
    <option>Chupacabra</option>,
    <option>Chuul</option>,
    <option>Cloaker</option>,
    <option>Clockwork Dragon</option>,
    <option>Clockwork Goliath</option>,
    <option>Clockwork Leviathan</option>,
    <option>Clockwork Mage</option>,
    <option>Clockwork Servant</option>,
    <option>Clockwork Soldier</option>,
    <option>Clockwork Spy</option>,
    <option>Clockwork Steed</option>,
    <option>Cockatrice</option>,
    <option>Cockroach, Giant</option>,
    <option>Cold Rider</option>,
    <option>Colour Out of Space</option>,
    <option>Comozant Wyrd</option>,
    <option>Contemplative</option>,
    <option>Couatl</option>,
    <option>Crab, Giant</option>,
    <option>Crab, Shark-Eating</option>,
    <option>Crab, Shipwrecker</option>,
    <option>Crawling Hand</option>,
    <option>Crawling Hand, Giant</option>,
    <option>Crocodile</option>,
    <option>Crocodile, Dire</option>,
    <option>Crypt Thing</option>,
    <option>Crysmal</option>,
    <option>Cthulhu</option>,
    <option>Cyclops</option>,
    <option>Cyclops, Great</option>,
    <option>D'ziriak</option>,
    <option>Daemon, Astradaemon</option>,
    <option>Daemon, Cacodaemon</option>,
    <option>Daemon, Ceustodaemon</option>,
    <option>Daemon, Crucidaemon</option>,
    <option>Daemon, Derghodaemon</option>,
    <option>Daemon, Hydrodaemon</option>,
    <option>Daemon, Leukodaemon</option>,
    <option>Daemon, Meladaemon</option>,
    <option>Daemon, Piscodaemon</option>,
    <option>Daemon, Purrodaemon</option>,
    <option>Daemon, Thanadaemon</option>,
    <option>Daemon, Vulnudaemon</option>,
    <option>Dagon</option>,
    <option>Dark Caller</option>,
    <option>Dark Creeper</option>,
    <option>Dark Dancer</option>,
    <option>Dark Slayer</option>,
    <option>Dark Stalker</option>,
    <option>Darkmantle</option>,
    <option>Death Dog</option>,
    <option>Death Worm</option>,
    <option>Deathtrap Ooze</option>,
    <option>Deathweb</option>,
    <option>Decapus</option>,
    <option>Demilich</option>,
    <option>Demodand, Shaggy</option>,
    <option>Demodand, Slimy</option>,
    <option>Demodand, Tarry</option>,
    <option>Demon, Babau</option>,
    <option>Demon, Balor</option>,
    <option>Demon, Coloxus</option>,
    <option>Demon, Dretch</option>,
    <option>Demon, Glabrezu</option>,
    <option>Demon, Hezrou</option>,
    <option>Demon, Incubus</option>,
    <option>Demon, Kalavakus</option>,
    <option>Demon, Marilith</option>,
    <option>Demon, Nabasu</option>,
    <option>Demon, Nalfeshnee</option>,
    <option>Demon, Omox</option>,
    <option>Demon, Quasit</option>,
    <option>Demon, Schir</option>,
    <option>Demon, Shadow</option>,
    <option>Demon, Shemhazian</option>,
    <option>Demon, Succubus</option>,
    <option>Demon, Vrock</option>,
    <option>Demon, Vrolikai</option>,
    <option>Denizen of Leng</option>,
    <option>Derhii</option>,
    <option>Derro</option>,
    <option>Destrachan</option>,
    <option>Devil, Accuser</option>,
    <option>Devil, Barbed</option>,
    <option>Devil, Bearded</option>,
    <option>Devil, Belier</option>,
    <option>Devil, Bone</option>,
    <option>Devil, Contract</option>,
    <option>Devil, Drowning</option>,
    <option>Devil, Erinyes</option>,
    <option>Devil, Handmaiden</option>,
    <option>Devil, Horned</option>,
    <option>Devil, Host</option>,
    <option>Devil, Ice</option>,
    <option>Devil, Immolation</option>,
    <option>Devil, Imp</option>,
    <option>Devil, Lemure</option>,
    <option>Devil, Nemesis</option>,
    <option>Devilbound Creature</option>,
    <option>Devilfish</option>,
    <option>Devourer</option>,
    <option>Dhampir</option>,
    <option>Dinosaur, Allosaurus</option>,
    <option>Dinosaur, Ankylosaurus</option>,
    <option>Dinosaur, Brachiosaurus</option>,
    <option>Dinosaur, Compsognathus</option>,
    <option>Dinosaur, Deinonychus</option>,
    <option>Dinosaur, Dimetrodon</option>,
    <option>Dinosaur, Dimorphodon</option>,
    <option>Dinosaur, Diplodocus</option>,
    <option>Dinosaur, Elasmosaurus</option>,
    <option>Dinosaur, Iguanodon</option>,
    <option>Dinosaur, Pachycephalosaurus</option>,
    <option>Dinosaur, Parasaurolophus</option>,
    <option>Dinosaur, Pteranodon</option>,
    <option>Dinosaur, Spinosaurus</option>,
    <option>Dinosaur, Stegosaurus</option>,
    <option>Dinosaur, Styracosaurus</option>,
    <option>Dinosaur, Triceratops</option>,
    <option>Dinosaur, Tylosaurus</option>,
    <option>Dinosaur, Tyrannosaurus</option>,
    <option>Dinosaur, Velociraptor</option>,
    <option>Dire Corby</option>,
    <option>Disenchanter</option>,
    <option>Div, Aghash</option>,
    <option>Div, Akvan</option>,
    <option>Div, Doru</option>,
    <option>Div, Ghawwas</option>,
    <option>Div, Pairaka</option>,
    <option>Div, Sepid</option>,
    <option>Div, Shira</option>,
    <option>Divine Guardian</option>,
    <option>Dodo</option>,
    <option>Dog</option>,
    <option>Dog, Riding</option>,
    <option>Dolphin</option>,
    <option>Dolphin, Orca</option>,
    <option>Doppelganger</option>,
    <option>Dorvae</option>,
    <option>Dossenus</option>,
    <option>Dracolisk</option>,
    <option>Dragon Horse</option>,
    <option>Dragon Turtle</option>,
    <option>Dragon, Chromatic, Black</option>,
    <option>Dragon, Chromatic, Black, Adult</option>,
    <option>Dragon, Chromatic, Black, Ancient</option>,
    <option>Dragon, Chromatic, Black, Young</option>,
    <option>Dragon, Chromatic, Blue</option>,
    <option>Dragon, Chromatic, Blue, Adult</option>,
    <option>Dragon, Chromatic, Blue, Ancient</option>,
    <option>Dragon, Chromatic, Blue, Young</option>,
    <option>Dragon, Chromatic, Green</option>,
    <option>Dragon, Chromatic, Green, Adult</option>,
    <option>Dragon, Chromatic, Green, Ancient</option>,
    <option>Dragon, Chromatic, Green, Young</option>,
    <option>Dragon, Chromatic, Red</option>,
    <option>Dragon, Chromatic, Red, Adult</option>,
    <option>Dragon, Chromatic, Red, Ancient</option>,
    <option>Dragon, Chromatic, Red, Young</option>,
    <option>Dragon, Chromatic, White</option>,
    <option>Dragon, Chromatic, White, Adult</option>,
    <option>Dragon, Chromatic, White, Ancient</option>,
    <option>Dragon, Chromatic, White, Young</option>,
    <option>Dragon, Faerie</option>,
    <option>Dragon, Imperial, Forest</option>,
    <option>Dragon, Imperial, Forest, Adult</option>,
    <option>Dragon, Imperial, Forest, Ancient</option>,
    <option>Dragon, Imperial, Forest, Young</option>,
    <option>Dragon, Imperial, Sea</option>,
    <option>Dragon, Imperial, Sea, Adult</option>,
    <option>Dragon, Imperial, Sea, Ancient</option>,
    <option>Dragon, Imperial, Sea, Young</option>,
    <option>Dragon, Imperial, Sky</option>,
    <option>Dragon, Imperial, Sky, Adult</option>,
    <option>Dragon, Imperial, Sky, Ancient</option>,
    <option>Dragon, Imperial, Sky, Young</option>,
    <option>Dragon, Imperial, Sovereign</option>,
    <option>Dragon, Imperial, Sovereign, Adult</option>,
    <option>Dragon, Imperial, Sovereign, Ancient</option>,
    <option>Dragon, Imperial, Sovereign, Young</option>,
    <option>Dragon, Imperial, Underworld</option>,
    <option>Dragon, Imperial, Underworld, Adult</option>,
    <option>Dragon, Imperial, Underworld, Ancient</option>,
    <option>Dragon, Imperial, Underworld, Young</option>,
    <option>Dragon, Metallic, Brass</option>,
    <option>Dragon, Metallic, Brass, Adult</option>,
    <option>Dragon, Metallic, Brass, Ancient</option>,
    <option>Dragon, Metallic, Brass, Young</option>,
    <option>Dragon, Metallic, Bronze</option>,
    <option>Dragon, Metallic, Bronze, Adult</option>,
    <option>Dragon, Metallic, Bronze, Ancient</option>,
    <option>Dragon, Metallic, Bronze, Young</option>,
    <option>Dragon, Metallic, Copper</option>,
    <option>Dragon, Metallic, Copper, Adult</option>,
    <option>Dragon, Metallic, Copper, Ancient</option>,
    <option>Dragon, Metallic, Copper, Young</option>,
    <option>Dragon, Metallic, Gold</option>,
    <option>Dragon, Metallic, Gold, Adult</option>,
    <option>Dragon, Metallic, Gold, Ancient</option>,
    <option>Dragon, Metallic, Gold, Young</option>,
    <option>Dragon, Metallic, Silver</option>,
    <option>Dragon, Metallic, Silver, Adult</option>,
    <option>Dragon, Metallic, Silver, Ancient</option>,
    <option>Dragon, Metallic, Silver, Young</option>,
    <option>Dragon, Outer, Lunar</option>,
    <option>Dragon, Outer, Lunar, Adult</option>,
    <option>Dragon, Outer, Lunar, Ancient</option>,
    <option>Dragon, Outer, Lunar, Young</option>,
    <option>Dragon, Outer, Solar</option>,
    <option>Dragon, Outer, Solar, Adult</option>,
    <option>Dragon, Outer, Solar, Ancient</option>,
    <option>Dragon, Outer, Solar, Young</option>,
    <option>Dragon, Outer, Time</option>,
    <option>Dragon, Outer, Time, Adult</option>,
    <option>Dragon, Outer, Time, Ancient</option>,
    <option>Dragon, Outer, Time, Young</option>,
    <option>Dragon, Outer, Void</option>,
    <option>Dragon, Outer, Void, Adult</option>,
    <option>Dragon, Outer, Void, Ancient</option>,
    <option>Dragon, Outer, Void, Young</option>,
    <option>Dragon, Outer, Vortex</option>,
    <option>Dragon, Outer, Vortex, Adult</option>,
    <option>Dragon, Outer, Vortex, Ancient</option>,
    <option>Dragon, Outer, Vortex, Young</option>,
    <option>Dragon, Primal, Brine</option>,
    <option>Dragon, Primal, Brine, Adult</option>,
    <option>Dragon, Primal, Brine, Ancient</option>,
    <option>Dragon, Primal, Brine, Young</option>,
    <option>Dragon, Primal, Cloud</option>,
    <option>Dragon, Primal, Cloud, Adult</option>,
    <option>Dragon, Primal, Cloud, Ancient</option>,
    <option>Dragon, Primal, Cloud, Young</option>,
    <option>Dragon, Primal, Crystal</option>,
    <option>Dragon, Primal, Crystal, Adult</option>,
    <option>Dragon, Primal, Crystal, Ancient</option>,
    <option>Dragon, Primal, Crystal, Young</option>,
    <option>Dragon, Primal, Magma</option>,
    <option>Dragon, Primal, Magma, Adult</option>,
    <option>Dragon, Primal, Magma, Ancient</option>,
    <option>Dragon, Primal, Magma, Young</option>,
    <option>Dragon, Primal, Umbral, Adult</option>,
    <option>Dragon, Primal, Umbral, Ancient</option>,
    <option>Dragon, Primal, Umbral, Young</option>,
    <option>Dragonne</option>,
    <option>Drakainia</option>,
    <option>Drake, Desert</option>,
    <option>Drake, Flame</option>,
    <option>Drake, Forest</option>,
    <option>Drake, Frost</option>,
    <option>Drake, Lava</option>,
    <option>Drake, Mist</option>,
    <option>Drake, Rift</option>,
    <option>Drake, River</option>,
    <option>Drake, Sea</option>,
    <option>Drake, Shadow</option>,
    <option>Drake, Spire</option>,
    <option>Draugr</option>,
    <option>Drider</option>,
    <option>Drow</option>,
    <option>Drow Noble</option>,
    <option>Dryad</option>,
    <option>Duergar</option>,
    <option>Dullahan</option>,
    <option>Dust Digger</option>,
    <option>Dwarf</option>,
    <option>Dybbuk</option>,
    <option>Eagle</option>,
    <option>Eagle, Giant</option>,
    <option>Ecorche</option>,
    <option>Ectoplasmic Creature</option>,
    <option>Eel, Electric</option>,
    <option>Eel, Giant Moray</option>,
    <option>Eidolon, Unfettered</option>,
    <option>Einherji</option>,
    <option>Elder Thing</option>,
    <option>Elemental, Air</option>,
    <option>Elemental, Air, Elder</option>,
    <option>Elemental, Air, Greater</option>,
    <option>Elemental, Air, Huge</option>,
    <option>Elemental, Air, Large</option>,
    <option>Elemental, Air, Medium</option>,
    <option>Elemental, Air, Small</option>,
    <option>Elemental, Earth</option>,
    <option>Elemental, Earth, Elder</option>,
    <option>Elemental, Earth, Greater</option>,
    <option>Elemental, Earth, Huge</option>,
    <option>Elemental, Earth, Large</option>,
    <option>Elemental, Earth, Medium</option>,
    <option>Elemental, Earth, Small</option>,
    <option>Elemental, Fire</option>,
    <option>Elemental, Fire, Elder</option>,
    <option>Elemental, Fire, Greater</option>,
    <option>Elemental, Fire, Huge</option>,
    <option>Elemental, Fire, Large</option>,
    <option>Elemental, Fire, Medium</option>,
    <option>Elemental, Fire, Small</option>,
    <option>Elemental, Ice</option>,
    <option>Elemental, Ice, Elder</option>,
    <option>Elemental, Ice, Greater</option>,
    <option>Elemental, Ice, Huge</option>,
    <option>Elemental, Ice, Large</option>,
    <option>Elemental, Ice, Medium</option>,
    <option>Elemental, Ice, Small</option>,
    <option>Elemental, Lightning</option>,
    <option>Elemental, Lightning, Elder</option>,
    <option>Elemental, Lightning, Greater</option>,
    <option>Elemental, Lightning, Huge</option>,
    <option>Elemental, Lightning, Large</option>,
    <option>Elemental, Lightning, Medium</option>,
    <option>Elemental, Lightning, Small</option>,
    <option>Elemental, Magma</option>,
    <option>Elemental, Magma, Elder</option>,
    <option>Elemental, Magma, Greater</option>,
    <option>Elemental, Magma, Huge</option>,
    <option>Elemental, Magma, Large</option>,
    <option>Elemental, Magma, Medium</option>,
    <option>Elemental, Magma, Small</option>,
    <option>Elemental, Mud</option>,
    <option>Elemental, Mud, Elder</option>,
    <option>Elemental, Mud, Greater</option>,
    <option>Elemental, Mud, Huge</option>,
    <option>Elemental, Mud, Large</option>,
    <option>Elemental, Mud, Medium</option>,
    <option>Elemental, Mud, Small</option>,
    <option>Elemental, Water</option>,
    <option>Elemental, Water, Elder</option>,
    <option>Elemental, Water, Greater</option>,
    <option>Elemental, Water, Huge</option>,
    <option>Elemental, Water, Large</option>,
    <option>Elemental, Water, Medium</option>,
    <option>Elemental, Water, Small</option>,
    <option>Elephant</option>,
    <option>Elephant, Mastodon</option>,
    <option>Elf</option>,
    <option>Elohim</option>,
    <option>Erlking</option>,
    <option>Ettercap</option>,
    <option>Ettin</option>,
    <option>Executioner's Hood</option>,
    <option>Faceless Stalker</option>,
    <option>Fachen</option>,
    <option>Faun</option>,
    <option>Festering Spirit</option>,
    <option>Festrog</option>,
    <option>Fetchling</option>,
    <option>Fey Creature</option>,
    <option>Flail Snail</option>,
    <option>Flesh Colossus</option>,
    <option>Fleshdreg</option>,
    <option>Fleshwarp, Ghonhatine</option>,
    <option>Fleshwarp, Grothlut</option>,
    <option>Fleshwarp, Halsora</option>,
    <option>Fleshwarp, Irnakurse</option>,
    <option>Flumph</option>,
    <option>Fly, Giant</option>,
    <option>Flying Polyp</option>,
    <option>Flytrap, Giant</option>,
    <option>Foo Dog</option>,
    <option>Foo Lion</option>,
    <option>Forlarren</option>,
    <option>Formian Myrmarch</option>,
    <option>Formian Queen</option>,
    <option>Formian Taskmaster</option>,
    <option>Formian Warrior</option>,
    <option>Formian Worker</option>,
    <option>Fossegrim</option>,
    <option>Freezing Flow</option>,
    <option>Frog, Giant</option>,
    <option>Frog, Poison</option>,
    <option>Froghemoth</option>,
    <option>Frost Worm</option>,
    <option>Fungal Crawler</option>,
    <option>Fungal Creature</option>,
    <option>Gaki</option>,
    <option>Gallowdead</option>,
    <option>Galvo</option>,
    <option>Gar</option>,
    <option>Gar, Giant</option>,
    <option>Garden Ooze</option>,
    <option>Gargoyle</option>,
    <option>Garuda</option>,
    <option>Gashadokuro</option>,
    <option>Gathlain</option>,
    <option>Gearghost</option>,
    <option>Geist</option>,
    <option>Gelatinous Cube</option>,
    <option>Genie, Djinni</option>,
    <option>Genie, Efreeti</option>,
    <option>Genie, Janni</option>,
    <option>Genie, Marid</option>,
    <option>Genie, Shaitan</option>,
    <option>Gholdako</option>,
    <option>Ghorazagh</option>,
    <option>Ghost</option>,
    <option>Ghoul</option>,
    <option>Ghul</option>,
    <option>Giant Adult Ant Lion</option>,
    <option>Giant Ant Lion</option>,
    <option>Giant Dragonfly</option>,
    <option>Giant Dragonfly Nymph</option>,
    <option>Giant Phantom Armor</option>,
    <option>Giant Queen Bee</option>,
    <option>Giant, Ash</option>,
    <option>Giant, Cave</option>,
    <option>Giant, Cliff</option>,
    <option>Giant, Cloud</option>,
    <option>Giant, Desert</option>,
    <option>Giant, Fire</option>,
    <option>Giant, Frost</option>,
    <option>Giant, Hill</option>,
    <option>Giant, Jungle</option>,
    <option>Giant, Marsh</option>,
    <option>Giant, Ocean</option>,
    <option>Giant, River</option>,
    <option>Giant, Rune</option>,
    <option>Giant, Slag</option>,
    <option>Giant, Stone</option>,
    <option>Giant, Storm</option>,
    <option>Giant, Taiga</option>,
    <option>Giant, Wood</option>,
    <option>Gibbering Mouther</option>,
    <option>Girallon</option>,
    <option>Girtablilu</option>,
    <option>Globster</option>,
    <option>Gloomwing</option>,
    <option>Gnoll</option>,
    <option>Gnome</option>,
    <option>Goblin</option>,
    <option>Goblin Dog</option>,
    <option>Goblin Snake</option>,
    <option>Golem, Adamantine</option>,
    <option>Golem, Alchemical</option>,
    <option>Golem, Blood</option>,
    <option>Golem, Bone</option>,
    <option>Golem, Brass</option>,
    <option>Golem, Cannon</option>,
    <option>Golem, Carrion</option>,
    <option>Golem, Clay</option>,
    <option>Golem, Clockwork</option>,
    <option>Golem, Flesh</option>,
    <option>Golem, Fossil</option>,
    <option>Golem, Glass</option>,
    <option>Golem, Ice</option>,
    <option>Golem, Iron</option>,
    <option>Golem, Mithral</option>,
    <option>Golem, Stone</option>,
    <option>Golem, Wood</option>,
    <option>Gorgon</option>,
    <option>Gorynych</option>,
    <option>Graeae</option>,
    <option>Graveknight</option>,
    <option>Graven Guardian</option>,
    <option>Gray Ooze</option>,
    <option>Gray Render</option>,
    <option>Green Hag</option>,
    <option>Gremlin, Fuath</option>,
    <option>Gremlin, Grimple</option>,
    <option>Gremlin, Haniver</option>,
    <option>Gremlin, Jinkin</option>,
    <option>Gremlin, Monaciello</option>,
    <option>Gremlin, Nuglub</option>,
    <option>Gremlin, Pugwampi</option>,
    <option>Gremlin, Vexgit</option>,
    <option>Grendel</option>,
    <option>Grick</option>,
    <option>Griffon</option>,
    <option>Grig</option>,
    <option>Grindylow</option>,
    <option>Grippli</option>,
    <option>Grodair</option>,
    <option>Grootslang</option>,
    <option>Gryph</option>,
    <option>Guardian Dragon</option>,
    <option>Guardian Phantom Armor</option>,
    <option>Guecubu</option>,
    <option>Gug</option>,
    <option>Half-Celestial</option>,
    <option>Half-elf</option>,
    <option>Half-Fiend</option>,
    <option>Half-orc</option>,
    <option>Halfling</option>,
    <option>Hamadryad</option>,
    <option>Hangman Tree</option>,
    <option>Harionago</option>,
    <option>Harpy</option>,
    <option>Hastur</option>,
    <option>Hell Hound</option>,
    <option>Hell Hound, Nessian</option>,
    <option>Hellcat</option>,
    <option>Herd Animal, Antelope</option>,
    <option>Herd Animal, Aurochs</option>,
    <option>Herd Animal, Bison</option>,
    <option>Herd Animal, Camel</option>,
    <option>Herd Animal, Elk</option>,
    <option>Herd Animal, Giraffe</option>,
    <option>Herd Animal, Ram</option>,
    <option>Herd Animal, Stag</option>,
    <option>Hippocampus</option>,
    <option>Hippogriff</option>,
    <option>Hippopotamus</option>,
    <option>Hippopotamus, Behemoth</option>,
    <option>Hobgoblin</option>,
    <option>Hodag</option>,
    <option>Hollow Serpent</option>,
    <option>Homunculus</option>,
    <option>Horse</option>,
    <option>Horse, Pony</option>,
    <option>Hound of Tindalos</option>,
    <option>Howler</option>,
    <option>Huecuva</option>,
    <option>Huldra</option>,
    <option>Human</option>,
    <option>Human Skeleton</option>,
    <option>Human Zombie</option>,
    <option>Humbaba</option>,
    <option>Hungry Flesh</option>,
    <option>Hungry Fog</option>,
    <option>Hyakume</option>,
    <option>Hydra</option>,
    <option>Hyena</option>,
    <option>Hyena, Dire</option>,
    <option>Hypnalis</option>,
    <option>Ifrit</option>,
    <option>Ijiraq</option>,
    <option>Iku-Turso</option>,
    <option>Immortal Ichor</option>,
    <option>Incutilis</option>,
    <option>Inevitable, Arbiter</option>,
    <option>Inevitable, Kolyarut</option>,
    <option>Inevitable, Lhaksharut</option>,
    <option>Inevitable, Marut</option>,
    <option>Inevitable, Zelekhut</option>,
    <option>Intellect Devourer</option>,
    <option>Invisible Stalker</option>,
    <option>Irminsul</option>,
    <option>Iron Cobra</option>,
    <option>Iron Colossus</option>,
    <option>Isitoq</option>,
    <option>Jabberwock</option>,
    <option>Jack-o'-Lantern</option>,
    <option>Jackalwere</option>,
    <option>Jellyfish, Death's Head</option>,
    <option>Jellyfish, Giant</option>,
    <option>Jellyfish, Sapphire</option>,
    <option>Jinmenju</option>,
    <option>Jorogumo</option>,
    <option>Jubjub Bird</option>,
    <option>Juggernaut</option>,
    <option>Julunggali</option>,
    <option>Juvenile Rukh</option>,
    <option>Jyoti</option>,
    <option>Kaiju, Agyra</option>,
    <option>Kaiju, Bezravnis</option>,
    <option>Kaiju, Mogaru</option>,
    <option>Kamadan</option>,
    <option>Kami, Jinushigami</option>,
    <option>Kami, Kodama</option>,
    <option>Kami, Shikigami</option>,
    <option>Kami, Toshigami</option>,
    <option>Kami, Zuishin</option>,
    <option>Kappa</option>,
    <option>Kapre</option>,
    <option>Karkinoi</option>,
    <option>Kasatha</option>,
    <option>Kech</option>,
    <option>Kelpie</option>,
    <option>Kirin</option>,
    <option>Kitsune</option>,
    <option>Kobold</option>,
    <option>Kongamato</option>,
    <option>Korred</option>,
    <option>Kostchtchie</option>,
    <option>Kraken</option>,
    <option>Krenshar</option>,
    <option>Kyton</option>,
    <option>Kyton, Augur</option>,
    <option>Kyton, Eremite</option>,
    <option>Kyton, Interlocutor</option>,
    <option>Kyton, Ostiarius</option>,
    <option>Kyton, Sacristan</option>,
    <option>Lamia</option>,
    <option>Lamia Matriarch</option>,
    <option>Lammasu</option>,
    <option>Lampad</option>,
    <option>Leaf Ray</option>,
    <option>Leanan Sidhe</option>,
    <option>Leech, Giant</option>,
    <option>Leng Spider</option>,
    <option>Leprechaun</option>,
    <option>Leshy, Fungus</option>,
    <option>Leshy, Gourd</option>,
    <option>Leshy, Leaf</option>,
    <option>Leshy, Seaweed</option>,
    <option>Leucrotta</option>,
    <option>Lich</option>,
    <option>Linnorm, Cairn</option>,
    <option>Linnorm, Crag</option>,
    <option>Linnorm, Fjord</option>,
    <option>Linnorm, Ice</option>,
    <option>Linnorm, Taiga</option>,
    <option>Linnorm, Tarn</option>,
    <option>Linnorm, Tor</option>,
    <option>Lion</option>,
    <option>Lion, Dire</option>,
    <option>Living Topiary</option>,
    <option>Living Wall</option>,
    <option>Lizard, Giant Chameleon</option>,
    <option>Lizard, Giant Frilled</option>,
    <option>Lizard, Giant Gecko</option>,
    <option>Lizard, Monitor</option>,
    <option>Lizardfolk</option>,
    <option>Locathah</option>,
    <option>Locust Swarm</option>,
    <option>Locust, Giant</option>,
    <option>Lorelei</option>,
    <option>Lukwata</option>,
    <option>Lunarma</option>,
    <option>Lurker Above</option>,
    <option>Lurker in Light</option>,
    <option>Maenad</option>,
    <option>Maftet</option>,
    <option>Maggot, Giant</option>,
    <option>Magma Ooze</option>,
    <option>Magmin</option>,
    <option>Manananggal</option>,
    <option>Mandragora</option>,
    <option>Manitou</option>,
    <option>Manticore</option>,
    <option>Mantis, Giant</option>,
    <option>Marsupial, Kangaroo</option>,
    <option>Marsupial, Thylacine</option>,
    <option>Medusa</option>,
    <option>Megafauna, Archelon</option>,
    <option>Megafauna, Arsinoitherium</option>,
    <option>Megafauna, Baluchitherium</option>,
    <option>Megafauna, Basilosaurus</option>,
    <option>Megafauna, Gylptodon</option>,
    <option>Megafauna, Megalania</option>,
    <option>Megafauna, Megaloceros</option>,
    <option>Megafauna, Megatherium</option>,
    <option>Mephit</option>,
    <option>Mercane</option>,
    <option>Merfolk</option>,
    <option>Merrow, Freshwater</option>,
    <option>Merrow, Saltwater</option>,
    <option>Mi-Go</option>,
    <option>Mihstu</option>,
    <option>Mimic</option>,
    <option>Mindslaver Mold</option>,
    <option>Minotaur</option>,
    <option>Mite</option>,
    <option>Mobogo</option>,
    <option>Mohrg</option>,
    <option>Mongrelman</option>,
    <option>Moon-Beast</option>,
    <option>Moonflower</option>,
    <option>Morlock</option>,
    <option>Mosquito, Giant</option>,
    <option>Mothman</option>,
    <option>Mu Spore</option>,
    <option>Mudlord</option>,
    <option>Mummified Creature</option>,
    <option>Mummy</option>,
    <option>Myceloid</option>,
    <option>Myrmecoleon</option>,
    <option>Naga, Dark</option>,
    <option>Naga, Guardian</option>,
    <option>Naga, Lunar</option>,
    <option>Naga, Royal</option>,
    <option>Naga, Spirit</option>,
    <option>Naga, Water</option>,
    <option>Nagaji</option>,
    <option>Necrocraft</option>,
    <option>Necrophidius</option>,
    <option>Neh-Thalggu</option>,
    <option>Neothelid</option>,
    <option>Nependis</option>,
    <option>Nephilim</option>,
    <option>Nereid</option>,
    <option>Night Hag</option>,
    <option>Nightgaunt</option>,
    <option>Nightmare</option>,
    <option>Nightmare Creature</option>,
    <option>Nightmare, Cauchemar</option>,
    <option>Nightshade, Nightcrawler</option>,
    <option>Nightshade, Nightwalker</option>,
    <option>Nightshade, Nightwave</option>,
    <option>Nightshade, Nightwing</option>,
    <option>Ningyo</option>,
    <option>Nixie</option>,
    <option>Norn</option>,
    <option>Nuckelavee</option>,
    <option>Nue</option>,
    <option>Nycar</option>,
    <option>Nymph</option>,
    <option>Oceanid</option>,
    <option>Ochre Jelly</option>,
    <option>Octopus</option>,
    <option>Octopus, Giant</option>,
    <option>Ogre</option>,
    <option>Ogrekin</option>,
    <option>Olethrodaemon</option>,
    <option>Oma</option>,
    <option>Oni, Fire Yai</option>,
    <option>Oni, Ice Yai</option>,
    <option>Oni, Kuwa</option>,
    <option>Oni, Ogre Mage</option>,
    <option>Oni, Spirit</option>,
    <option>Oni, Void Yai</option>,
    <option>Oni, Water Yai</option>,
    <option>Orc</option>,
    <option>Oread</option>,
    <option>Otyugh</option>,
    <option>Owb</option>,
    <option>Owl, Giant</option>,
    <option>Owl, Great Horned</option>,
    <option>Owlbear</option>,
    <option>Pale Stranger</option>,
    <option>Parasite, Giant Rot Grub</option>,
    <option>Pard</option>,
    <option>Pazuzu</option>,
    <option>Pech</option>,
    <option>Pegasus</option>,
    <option>Peluda</option>,
    <option>Penanggalen</option>,
    <option>Peri</option>,
    <option>Peryton</option>,
    <option>Petitioner</option>,
    <option>Phantom Fungus</option>,
    <option>Phase Spider</option>,
    <option>Phoenix</option>,
    <option>Phycomid</option>,
    <option>Pickled Punk</option>,
    <option>Pipefox</option>,
    <option>Pit Fiend</option>,
    <option>Pixie</option>,
    <option>Plasma Ooze</option>,
    <option>Platypus</option>,
    <option>Pod-Spawned Guard Captain</option>,
    <option>Poltergeist</option>,
    <option>Pooka</option>,
    <option>Popobala</option>,
    <option>Porcupine</option>,
    <option>Porcupine, Giant</option>,
    <option>Primate, Baboon</option>,
    <option>Protean, Imentesh</option>,
    <option>Protean, Keketar</option>,
    <option>Protean, Naunet</option>,
    <option>Protean, Voidworm</option>,
    <option>Pseudodragon</option>,
    <option>Psychopomp, Catrina</option>,
    <option>Psychopomp, Morrigna</option>,
    <option>Psychopomp, Nosoi</option>,
    <option>Psychopomp, Vanth</option>,
    <option>Psychopomp, Yamaraj</option>,
    <option>Pukwudgie</option>,
    <option>Purple Worm</option>,
    <option>Qallupilluk</option>,
    <option>Qlippoth, Augnagar</option>,
    <option>Qlippoth, Chernobue</option>,
    <option>Qlippoth, Cythnigot</option>,
    <option>Qlippoth, Iathavos</option>,
    <option>Qlippoth, Nyogoth</option>,
    <option>Qlippoth, Shoggti</option>,
    <option>Qlippoth, Thulgant</option>,
    <option>Quickling</option>,
    <option>Quickwood</option>,
    <option>Rakshasa</option>,
    <option>Rakshasa, Dandasuka</option>,
    <option>Rakshasa, Maharaja</option>,
    <option>Rakshasa, Marai</option>,
    <option>Rakshasa, Raktavarna</option>,
    <option>Rakshasa, Tataka</option>,
    <option>Rast</option>,
    <option>Rat King</option>,
    <option>Rat, Dire</option>,
    <option>Ratfolk</option>,
    <option>Ratling</option>,
    <option>Ravener</option>,
    <option>Ray, Manta</option>,
    <option>Ray, Stingray</option>,
    <option>Redcap</option>,
    <option>Reefclaw</option>,
    <option>Remorhaz</option>,
    <option>Retriever</option>,
    <option>Revenant</option>,
    <option>Rhinoceros</option>,
    <option>Rhinoceros, Woolly</option>,
    <option>Roc</option>,
    <option>Rokurokubi</option>,
    <option>Roper</option>,
    <option>Rukh</option>,
    <option>Rusalka</option>,
    <option>Rust Monster</option>,
    <option>Sabosan</option>,
    <option>Sagari</option>,
    <option>Saguaroi</option>,
    <option>Sahuagin</option>,
    <option>Salamander</option>,
    <option>Samsaran</option>,
    <option>Sandman</option>,
    <option>Sard</option>,
    <option>Sargassum Fiend</option>,
    <option>Sasquatch</option>,
    <option>Satyr</option>,
    <option>Sayona</option>,
    <option>Scarecrow</option>,
    <option>Sceaduinar</option>,
    <option>Scorpion, Black</option>,
    <option>Scorpion, Cave</option>,
    <option>Scorpion, Deadfall</option>,
    <option>Scorpion, Ghost</option>,
    <option>Scorpion, Giant</option>,
    <option>Scorpion, Giant Emperor</option>,
    <option>Scorpion, Greensting</option>,
    <option>Scylla</option>,
    <option>Sea Anemone, Giant</option>,
    <option>Sea Bonze</option>,
    <option>Sea Cat</option>,
    <option>Sea Hag</option>,
    <option>Sea Serpent</option>,
    <option>Sea Serpent, Deep</option>,
    <option>Seaweed Siren</option>,
    <option>Selkie</option>,
    <option>Seps</option>,
    <option>Seps, Juvenile</option>,
    <option>Serpentfolk</option>,
    <option>Seugathi</option>,
    <option>Shadow</option>,
    <option>Shadow Creature</option>,
    <option>Shadow Mastiff</option>,
    <option>Shadow, Greater</option>,
    <option>Shae</option>,
    <option>Shambling Mound</option>,
    <option>Shantak</option>,
    <option>Shard Slag</option>,
    <option>Shark</option>,
    <option>Shark, Bull</option>,
    <option>Shark, Dire</option>,
    <option>Shark, Great White</option>,
    <option>Shedu</option>,
    <option>Shinigami</option>,
    <option>Shining Child</option>,
    <option>Shobhad</option>,
    <option>Shocker Lizard</option>,
    <option>Shoggoth</option>,
    <option>Shredskin</option>,
    <option>Shriezyx</option>,
    <option>Shulsaga</option>,
    <option>Simurgh</option>,
    <option>Sinspawn</option>,
    <option>Siren</option>,
    <option>Siyokoy</option>,
    <option>Skeletal Champion</option>,
    <option>Skinstitch</option>,
    <option>Skulk</option>,
    <option>Skum</option>,
    <option>Skunk</option>,
    <option>Skunk, Giant</option>,
    <option>Sleipnir</option>,
    <option>Slime Mold</option>,
    <option>Slithering Tracker</option>,
    <option>Sloth</option>,
    <option>Slug, Giant</option>,
    <option>Slurk</option>,
    <option>Snake, Constrictor</option>,
    <option>Snake, Emperor Cobra</option>,
    <option>Snake, Giant Anaconda</option>,
    <option>Snake, Venomous</option>,
    <option>Snallygaster</option>,
    <option>Solifugid, Albino Cave</option>,
    <option>Solifugid, Giant</option>,
    <option>Soul Eater</option>,
    <option>Soulbound Doll</option>,
    <option>Soulbound Mannequin</option>,
    <option>Soulbound Shell</option>,
    <option>Soulsliver</option>,
    <option>Spawn of Yog-Sothoth</option>,
    <option>Spectre</option>,
    <option>Sphinx</option>,
    <option>Sphinx, Androsphinx</option>,
    <option>Sphinx, Criosphinx</option>,
    <option>Sphinx, Hieracosphinx</option>,
    <option>Spider Eater</option>,
    <option>Spider, Giant</option>,
    <option>Spider, Giant Black Widow</option>,
    <option>Spider, Giant Crab</option>,
    <option>Spider, Giant Tarantula</option>,
    <option>Spider, Goliath</option>,
    <option>Spider, Ogre</option>,
    <option>Spider, Scarlet</option>,
    <option>Spriggan</option>,
    <option>Spriggan (Large Size)</option>,
    <option>Spring-Heeled Jack</option>,
    <option>Sprite</option>,
    <option>Squid</option>,
    <option>Squid, Giant</option>,
    <option>Star-Spawn of Cthulhu</option>,
    <option>Stirge</option>,
    <option>Stone Colossus</option>,
    <option>Stymphalidies</option>,
    <option>Suli</option>,
    <option>Svartalfar</option>,
    <option>Svirfneblin</option>,
    <option>Swan Maiden</option>,
    <option>Swan, Trumpeter</option>,
    <option>Swarm, Amoeba</option>,
    <option>Swarm, Army Ant</option>,
    <option>Swarm, Bat</option>,
    <option>Swarm, Centipede</option>,
    <option>Swarm, Cockroach</option>,
    <option>Swarm, Crab</option>,
    <option>Swarm, Hellwasp</option>,
    <option>Swarm, Jellyfish</option>,
    <option>Swarm, Leech</option>,
    <option>Swarm, Mosquito</option>,
    <option>Swarm, Parasite, Rot Grub</option>,
    <option>Swarm, Primate, Monkey</option>,
    <option>Swarm, Rat</option>,
    <option>Swarm, Snake</option>,
    <option>Swarm, Snake, Venomous</option>,
    <option>Swarm, Spider</option>,
    <option>Swarm, Tick</option>,
    <option>Swarm, Wasp</option>,
    <option>Sylph</option>,
    <option>Taniniver</option>,
    <option>Tanuki</option>,
    <option>Taotieh</option>,
    <option>Tarrasque</option>,
    <option>Tatzlwyrm</option>,
    <option>Tendriculos</option>,
    <option>Tenebrous Worm</option>,
    <option>Tengu</option>,
    <option>Tentamort</option>,
    <option>Terra-Cotta Soldier</option>,
    <option>Thoqqua</option>,
    <option>Thrasfyr</option>,
    <option>Thriae Queen</option>,
    <option>Thriae Seer</option>,
    <option>Thriae Soldier</option>,
    <option>Thunderbird</option>,
    <option>Tiberolith</option>,
    <option>Tick, Giant</option>,
    <option>Tiefling</option>,
    <option>Tiger</option>,
    <option>Tiger, Dire</option>,
    <option>Tikbalang</option>,
    <option>Titan, Elysian</option>,
    <option>Titan, Hekatonkheires</option>,
    <option>Titan, Thanatotic</option>,
    <option>Toad, Giant</option>,
    <option>Toad, Glacier</option>,
    <option>Tojanida</option>,
    <option>Tooth Fairy</option>,
    <option>Tophet</option>,
    <option>Tortoise, Giant</option>,
    <option>Tortoise, Immense</option>,
    <option>Totenmaske</option>,
    <option>Trapper</option>,
    <option>Treant</option>,
    <option>Triton</option>,
    <option>Troglodyte</option>,
    <option>Troll</option>,
    <option>Troll, Ice</option>,
    <option>Troll, Jotund</option>,
    <option>Troll, Moss</option>,
    <option>Troll, Rock</option>,
    <option>Trollhound</option>,
    <option>Trox</option>,
    <option>Tuatara</option>,
    <option>Tunche</option>,
    <option>Tupilaq</option>,
    <option>Turtle, Giant Snapping</option>,
    <option>Turtle, Snapping</option>,
    <option>Twigjack</option>,
    <option>Tyrant Jelly</option>,
    <option>Tzitzimitl</option>,
    <option>Udaeus</option>,
    <option>Umbral Dragon</option>,
    <option>Undine</option>,
    <option>Unicorn</option>,
    <option>Urdefhan</option>,
    <option>Valkyrie</option>,
    <option>Vampire</option>,
    <option>Vampire, Jiang-Shi</option>,
    <option>Vampire, Nosferatu</option>,
    <option>Vampiric Mist</option>,
    <option>Vanara</option>,
    <option>Vargouille</option>,
    <option>Vegepygmy</option>,
    <option>Vemerak</option>,
    <option>Violet Fungus</option>,
    <option>Viper Vine</option>,
    <option>Vishkanya</option>,
    <option>Vodyanoi</option>,
    <option>Voonith</option>,
    <option>Vouivre</option>,
    <option>Vulture</option>,
    <option>Vulture, Giant</option>,
    <option>Walrus</option>,
    <option>Walrus, Emperor</option>,
    <option>Warsworn</option>,
    <option>Wasp, Giant</option>,
    <option>Water Orm</option>,
    <option>Water Strider, Giant</option>,
    <option>Water Strider, Nymph</option>,
    <option>Wayang</option>,
    <option>Weasel, Dire</option>,
    <option>Weasel, Giant</option>,
    <option>Weedwhip</option>,
    <option>Wendigo</option>,
    <option>Werebat (Human Form)</option>,
    <option>Werebat (Hybrid Form)</option>,
    <option>Werebear (Human Form)</option>,
    <option>Werebear (Hybrid Form)</option>,
    <option>Wereboar (Human Form)</option>,
    <option>Wereboar (Hybrid Form)</option>,
    <option>Werecrocodile (Human Form)</option>,
    <option>Werecrocodile (Hybrid Form)</option>,
    <option>Wererat (Human Form)</option>,
    <option>Wererat (Hybrid Form)</option>,
    <option>Wereshark (Human Form)</option>,
    <option>Wereshark (Hybrid Form)</option>,
    <option>Weretiger (Human Form)</option>,
    <option>Weretiger (Hybrid Form)</option>,
    <option>Werewolf</option>,
    <option>Werewolf (Hybrid Form)</option>,
    <option>Whale</option>,
    <option>Whale, Great White</option>,
    <option>Wickerman</option>,
    <option>Wight</option>,
    <option>Wikkawak</option>,
    <option>Will-o'-Wisp</option>,
    <option>Winter Hag</option>,
    <option>Winterwight</option>,
    <option>Witchfire</option>,
    <option>Witchwyrd</option>,
    <option>Wolf</option>,
    <option>Wolf-in-Sheep's-Clothing</option>,
    <option>Wolf, Dire</option>,
    <option>Wolverine</option>,
    <option>Wolverine, Dire</option>,
    <option>Worg</option>,
    <option>Worg, Winter Wolf</option>,
    <option>Worm That Walks</option>,
    <option>Wraith</option>,
    <option>Wyrwood</option>,
    <option>Wyvaran</option>,
    <option>Wyvern</option>,
    <option>Xacarba</option>,
    <option>Xanthos</option>,
    <option>Xenopterid</option>,
    <option>Xill</option>,
    <option>Xorn</option>,
    <option>Xtabay</option>,
    <option>Yaoguai</option>,
    <option>Yellow Musk Creeper</option>,
    <option>Yeth Hound</option>,
    <option>Yeti</option>,
    <option>Yithian</option>,
    <option>Ypotryll</option>,
    <option>Yrthak</option>,
    <option>Yuki-Onna</option>,
    <option>Zombie Lord</option>,
    <option>Zombie, Juju</option>,
    <option>Zomok</option>,
    <option>Zoog</option>,
    <option>Zuvembie</option>
];
export default MonsterOptions;