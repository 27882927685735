const npcCharacteristics = ['Always agrees but changes mind just as easily',
'Asks how much everything costs',
'Likes to act mysteriously',
'Makes snap judgments about people or situations and tries to diminish any later evidence contradicting that first impression',
'Makes token bets about minor things',
'When talking to someone says that person\'s name a lot',
'Haggles over everything',
'Brings own food and drink',
'Says everything in a profound way',
'Know-it-all',
'Polite, but calls attention to it with elaborate bows or other gestures',
'Constantly apologizes as a verbal tic',
'Makes lots of threats but swiftly backs down if challenged',
'Very mellow; advises people to take a philosophical approach to both success and failure',
'Says as little as possible',
'Snickers or laughs at the misfortune of others',
'Gives people nicknames or uses terms of endearment',
'Prays a lot',
'Gives people treats cookies, candy, etc.',
'Often appears surprised and slightly offended when spoken to',
'Tosses a coin to make decisions',
'Has a list of numbered rules or maxims covering different situations and quotes them when appropriate',
'Tells people the "real" reason they do things',
'Asks for advice or opinions about very unlikely situations',
'Speaks with great formality; never uses contractions and employs bigger words than necessary',
'Easily distracted by minor events in the area',
'Usually needs someone to explain a joke or metaphor',
'Has a particular core belief, potentially a rather odd one, and steadfastly looks at everything through the lens of that opinion',
'Likes to count things and have fun with numbers',
'Always tries to find a compromise',
'Always eating',
'Very bad liar',
'Asks rude questions without realizing they cause offense',
'Grumbles and complains about the difficulties involved in any requested activities',
'Very sensitive to criticism or conflict',
'Absent-minded',
'Blames trolls for every trouble he encounters',
'Intellectual bully',
'Makes allusions to events from history or mythology without bothering to explain them',
'Doesn\'t kill insects or other pests, gently moves them out of the way instead',
'Sniffs or tastes things',
'Makes lists and checks things off',
'Cannot keep a secret',
'Seems slightly surprised whenever anyone else has a good idea or does something productive',
'Easily moved to tears',
'Easily angered',
'Terrified of disease and sick people',
'Has a stock phrase and an accompanying gesture',
'Habitually mumbles a word that rhymes with the final word other people speak before they pause',
'Delighted by puns and other sorts of word games',
'Makes animal noises when feeling threatened or excited',
'A connoisseur of fine food and drink who insists on lecturing about it',
'Very superstitious; insists on carrying out elaborate practices to attract good luck and avoid bad',
'Never turns down a dare or challenge',
'Never uses one word when ten will do',
'Propositions any even remotely attractive person encountered but makes panicked excuses should someone accept the offer',
'Always has a reason why something won\'t work',
'Careless about possessions, spends lots of time searching for overlooked nearby objects',
'Tells boring stories about children or other beloved young people',
'Constantly suggesting ways to make activities more "fun" and "exciting"',
'Collects small, relatively worthless objects like spoons, salt shakers, or wine corks; enthusiastically inspects any encountered and extols their "unique" qualities',
'Dotes on an obnoxious pet',
'Ignores a loyal pet',
'Self-loathing to an almost violent degree',
'Occasionally chants annoying little rhymes',
'Acts like someone from a lower social class as a sign of solidarity but comes across as offensive instead',
'Openly scornful of organized religion and believes all gods are selfish liars',
'Gets angrier and more determined with each setback',
'Thinks most objects are magical wondrous items',
'Seems to lack a moral compass when making plans though not in everyday life; often proposes horrific solutions to minor problems',
'Quietly makes personal sacrifices to help others, including forgoing meals, "losing" warm clothing, and repaying nonexistent loans',
'Suffering from some terminal illness',
'Name-drops constantly',
'Does a terrible job rather than refuse an unpleasant or unwanted task',
'Never tires of learning new and interesting bits of knowledge',
'Complains about smells no one else notices',
'Can\'t stop drinking once starts',
'Trying to master some kind of performance skill like juggling or ventriloquism but not very good at it yet',
'Is a failed actor and blames everyone else for it',
'Has a seemingly endless font of gossip; never stops chattering about various rumors and scandals',
'Is a very distant noble and treats lower orders with disdain',
'Very cheerful; tries to raise downcast spirits with songs, jokes, and uplifting stories',
'Grows more and more relaxed the worse things get; conversely, on edge and nervous when things seem to go well',
'Carries around a notebook to write down important information but has trouble reading own handwriting',
'Makes up seemingly arbitrary rules of etiquette "Redheads always sit on the left side of the table!"',
'Avoids making any kind of physical contact; grows noticeably repulsed if touched and tries to clean self as soon as possible',
'Always tries to be the center of attention',
'Is a little unhinged when the moon is full',
'Questions others about their background in order to determine if they are "suitable"',
'From a place with different customs; often asks for explanations of everyday things',
'Very jealous and possessive about a particular object or person; tends to view others as rivals and treat them as such',
'Continually mentions a heroic battle he was in and how nothing else compares',
'Gives people little colored cards to represent the emotional state they are creating blue for sad, red for angry, etc.',
'Explains simple things that don\'t need explanations',
'Wishes was a cat and seeks someone to polymorph him/her into one',
'Refers to self in third person',
'Has an imaginary ethereal friend',
'Has a habit of eating live insects without realizing it',
'Compulsively wipes or cleans things',
'Asks a kobold glove puppet its opinion at inopportune moments'
]

export default npcCharacteristics