const dungeonTypes = ['A hedge maze',
'A riverboat',
'A wizard\'s tomb',
'A bandit lair',
'A vast former mine now long abandoned',
'An abandoned casino',
'An old theater',
'Fog-shrouded dungeon that traps the PCs',
'Chambers where gravity regularly reverses',
'Moving clockwork dungeon',
'Incredibly narrow limestone gorges',
'Planes linked by portals',
'The belly of a ship-swallowing sea creature',
'A perilous bog and quicksand-filled mire',
'A slavers\' headquarters',
'Rapids and river channels sweeping through a valley',
'A giant tree riddled with many crevices and hollows',
'A melting iceberg',
'A monster infested palace of glass',
'The slowly flooding dungeon',
'The afterlife',
'A dream',
'Sunken ancient green byways linking fey lands',
'A nightmare',
'An iron obelisk of great size',
'A floating bog containing sunken tombs',
'An evil giant\'s castle',
'A partially submerged clock tower',
'A floating castle that appears only at night',
'A massive, partially hollow, slowly melting candle',
'A graveyard of giant skeletons',
'A glacier of poisoned water',
'An undead town where life goes on',
'The massive skull of an ancient dragon',
'A sunken iron ship below a fenland',
'A tangled forest of thorny trees',
'A giant insect-infested temple',
'A volcanic tomb',
'A living iron ship',
'A sinking maze',
'Endless stone bridges across a void of purple fog',
'A huge web',
'Giant rat tunnels',
'A colossal walking iron man',
'A living iron maze',
'What\'s down the plug-hole of a wizard\'s sink',
'A garden of poisonous living fungi',
'A sentient tomb',
'A solid storm cloud',
'A giant hourglass slowly filling with sand'
]

export default dungeonTypes