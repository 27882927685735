const dungeonLocations = ['An underground river',
'Within a river dam',
'Amid the rubble of a ruined citadel',
'Below a graveyard',
'In an insane asylum',
'Inside an ice flow',
'In another dimension/demiplane',
'Within earthquake-plagued caverns',
'In a hollow mountain',
'Deep in a desert of shifting tunnels',
'Abutting to a secret escape route',
'In a pyramid',
'Below a masoleum',
'Within a masoleum',
'Under a castle',
'Part of a menagerie',
'In a lighthouse',
'Under a tower',
'In a prison',
'Within a cathedral',
'Under the docks',
'In the sewers',
'Amid an underground canal',
'Built into an arena',
'Part of a forgotten labyrinth',
'Connecting to a beast\'s den',
'Under a tavern',
'In a cliff village',
'Inside a giant tree',
'Part of a frozen city',
'Inside a painting',
'In a gigantic statue',
'Under a roc nest',
'Within a sunken ruin',
'Hidden inside a giant egg',
'Formed within a massive fossil',
'Amid the rubble of a ruined giant city',
'Inside a crashed space vessel',
'Unearthed by a giant ant nest',
'Grown within a deity\'s corpse',
'On an island in a volcano',
'Inside a moon, star, or another planet'
]

export default dungeonLocations