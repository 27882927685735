const dungeonRooms = ['Antechamber',
'Armory',
'Atrium',
'Audience chamber',
'Banquet hall',
'Barracks',
'Belfry',
'Bestiary/menagerie',
'Chapel',
'Choke pointambushes, controlling flow of invaders',
'Closet',
'Cold room storage of perishable goods',
'Common room',
'Crypt',
'Dining hall',
'Elevator',
'Gallery',
'Garbage pit',
'Grand library',
'Great hall',
'Greenhouse',
'Guard room',
'Guest room',
'Infirmary',
'Jail',
'Kennel',
'Kitchen',
'Laboratory',
'Library',
'Living quarters communal',
'Living quarters family',
'Living quarters personal',
'Map room',
'Nursery',
'Observatory',
'Pantry',
'Parlor',
'Pool',
'Privy',
'Shrine',
'Slave pit',
'Smithy',
'Spring',
'Stable',
'Storeroom',
'Sunroom',
'Target range',
'Temple',
'Throne room',
'Torture chamber',
'Training hall',
'Trophy room',
'Vault',
'Well',
'Workroom',
'Workshop'
]

export default dungeonRooms