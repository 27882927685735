const npcGoals = [
'Get a good night\'s sleep',
'Sire a child',
'Prove noble heritage',
'Visit the next village',
'Solve a mystery no one else really cares about',
'Earn enough money to retire',
'Climb a mountain',
'Get a different, and better, reputation',
'Make friends with the PCs',
'Erase past failures with a single dramatic act',
'Move out of parents\' house',
'Get in better shape and learn to fight',
'See the ocean',
'See a particular holy text, fresco, or building',
'Find a new home for a mistreated animal',
'Get into the history books',
'Return home despite obstacles',
'Overcome a significant personal vice',
'Get proof that the afterlife exists before dying',
'Travel',
'Get married',
'Humiliate a rival',
'Find a missing child',
'Learn to gamble',
'Carry on a family tradition, like enlisting in the army',
'Go on a pilgrimage',
'Marry a childhood sweetheart',
'Commit a holy text to memory',
'Complete some sort of creative work write a play, carve a statue, etc.',
'Find a better job',
'Avoid bankruptcy',
'Impress a disapproving parent',
'Impress a love interest',
'Achieve a higher social rank',
'Start own business',
'Help child get a good start in life',
'Become the recipient of an actual miracle',
'Redeem family name',
'Hunt and kill a particular sort of monster',
'Continue to live in family estate despite danger',
'Solve a murder',
'Cross an ocean',
'Discover the meaning of life',
'See an angel',
'Murder someone',
'Get cured of a disease or other affliction',
'Become a monster',
'Become a hero',
'Marry a prince/princess',
'Rule a country'
];
export default npcGoals;