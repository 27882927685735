const backgrounds = ['Military veteran',
'Reformed criminal',
'Comes from a long line of tanners',
'Once owned an inn that was burnt down by bandits',
'Former alchemy lab assistant',
'Disgraced noble',
'Fought on losing side of civil war/revolution',
'Former prostitute',
'Refugee from land overrun by evil',
'Pious member of a notorious family',
'Ran away from a duel',
'Left at the altar',
'Criminal who retired after betraying rest of gang',
'Orphaned',
'Recovering addict',
'Childhood playmate of somebody important',
'Killed someone in self-defense',
'Escaped slave',
'Falsely convicted and then escaped from jail',
'Former indentured servant',
'Ran away as a youth and joined the circus',
'Abandoned spouse and children',
'Former sickly child who overcompensates as an adult',
'Failed priest',
'Failed merchant',
'Passed a guild test but too disillusioned to practice',
'Outwitted powerful monster',
'Practiced magic before a traumatizing accident',
'Died but came back through magic',
'Lost a magic item with potent abilities',
'Ran for office and suffered a humiliating defeat',
'Inadvertently saved the life of a future villain',
'Lost a spouse or child',
'Used to have to beg for food',
'Former artist suffering from a creative block',
'Raised by members of a different race',
'Former witch hunter',
'Pledged to keep a fantastic secret',
'Wanted for serious crime',
'Oppressed for race, sexuality, religion, etc.',
'Monster reincarnated as a human'
]

export default backgrounds;