const secrets = [
'Knows where a magic item is buried',
'Knows the best fishing spot',
'Knows who the murderer is',
'Is making liquor illegally',
'Knows why no one swims in the millpond anymore',
'Knows how to safely cook a poisonous fish',
'Is having an affair',
'Steals from his neighbors',
'Is a habitual liar',
'Is secretly related to another NPC',
'Knows what happened to all the rats',
'Is being blackmailed',
'Drinks heavily',
'Has a secret stash of funds',
'Is beaten by his/her spouse',
'Knows proper way to read a treasure map',
'Is an obsessive collector or hoarder',
'Beats offspring regularly',
'Is quietly religious',
'Knows location of a bandit hideout',
'Knows a particular monster�s favorite snack',
'Is a spy',
'Hears voices in the graveyard',
'Knows who really runs the neighborhood',
'Knows where to contact the fey',
'Is an assassin',
'Has a secret illness',
'Knows why no one in the village eats meat anymore',
'Knows how to get the oracle to answer truthfully',
'Engages in some deviant behavior',
'Knows command word for a magic item',
'Owes the local moneylender substantial funds',
'Worships an evil deity',
'Knows some local secret',
'Has a secret identity',
'Was a very different creature prior to reincarnation',
'Knows how to placate an angry ghost',
'Is a member of a secret local cult',
'Is wanted for a crime',
'Murdered spouse',
'Makes secret donations',
'Lost paladinhood due to cowardice',
'Is a paladin working undercover',
'Is the bastard child of a noble',
'Knows when the heir to the throne sneaks away to visit an attractive peasant',
'Is terrified of a particular monster type',
'Has some orc blood in their family',
'Has some troll blood in their family',
'Knows where the meteor landed',
'Knows someone is not what appears to be'
];

export default secrets;