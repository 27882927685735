const npcCharacteristics = ['Warts',
'Bad breath',
'Big nose',
'Long fingers',
'Stubby fingers',
'Boils',
'Very clean',
'Very white teeth',
'Dazzling eyes',
'Sweet smile',
'Beautiful curves/muscles',
'Dirty nails',
'Dirty hands',
'Calloused hands',
'Eye patch',
'Glass eye',
'Glasses',
'Enormous sideburns',
'Yellow teeth',
'Scratches a lot',
'Sneezes a lot',
'Compulsive blinking',
'Bites nails',
'Obviously dyed/unnaturally colored hair',
'Avoids making eye contact',
'Sweats a lot',
'Giggles',
'Hobbles',
'Jolly looking',
'Cracks knuckles',
'Whistles when talking',
'Cross-eyed',
'Harelipped',
'Rotten teeth',
'Generally filthy',
'Tattoo',
'Many tattoos',
'Covered in tattoos',
'One pierced ear',
'Pierced ears',
'Pierced nose',
'Pierced lip',
'Tribal scar on forearm',
'Winks a lot',
'Hacking cough',
'Spits',
'Dreadlocks',
'Different colored eyes',
'Missing teeth',
'Scarred',
'Twitches',
'Laughs nervously',
'Lisps',
'Limps',
'One eye',
'Missing a finger',
'Scarred face',
'Picks teeth nervously',
'No teeth',
'No fingers on one hand',
'Bald',
'Comb-over bald patch',
'Shaved head',
'Curly hair',
'Long hair',
'Short hair',
'Blonde hair',
'Black hair',
'Red hair',
'Gray hair',
'Big ears',
'Fat',
'Tall',
'Thin',
'Short',
'Homely',
'Handsome/beautiful',
'Moustache',
'Beard',
'Stubbly',
'Obscenely fat',
'Strangely tall',
'Unusually short',
'Double-chinned',
'Thin-lipped',
'Very hairy',
'Eyebrows meet',
'Wide mouthed',
'Missing a hand',
'Club-footed',
'Missing a leg',
'Missing an arm',
'Horrible facial scars',
'Clawed hands',
'Webbed hands',
'Scarred from pox',
'Terrible facial disease',
'Covered in cysts',
'Covered in pustules',
'Major deformity'
]

export default npcCharacteristics