const exoticRoomCharacteristics = [
'Fresco depicts grisly death of viewer',
'Filled with the sound of sobbing, coming from a crypt',
'Carved with faces depicting different alignments',
'Candelabrum sheds black light from violet candles',
'Statue on rotating pedestal',
'Is a huge trap',
'Walls like a rib cage',
'Crammed with mummified frogs',
'Iron spear rise randomly from a sieve-like floor',
'Fire bursts randomly from a floor covered in holes',
'Covered in pictures of evil peacock eyes',
'Has a huge violet eye fresco in its center',
'Appears to be the roots of some vast tree',
'Boiling mud crossed by very narrow stone bridges',
'Filled with giant spider husks of increasing size',
'Is filled with a huge cage',
'Has doors in the ceiling',
'Fountains carved to resemble hydras',
'Floor appear like a black lake but is actually glass',
'Raised terraces lead to central carving of bat-god',
'Slips sharply into a black pit of nothingness',
'Filled with the sounds of thunder',
'Is a huge shaft crossed by boughs of trees',
'Faceless statues point at one PC upon entry',
'Roof is stained glass window depicting spiders',
'Black fog appears suddenly',
'Iron weights drop from roof at random times',
'Endless stairway descends in room\'s center',
'Waterfall in room\'s center powers waterwheel',
'Magic voices sing hymns as the PCs enter',
'Is filled with explosive gas that is otherwise harmless',
'Is completely covered in tentacle carvings',
'Has eight huge bells suspended above the floor',
'Has a carving of a black devil-headed elephant',
'Every inch covered in angels',
'Iron devil statue speaks',
'Goes up and down',
'Entire floor pivots, dropping those who enter',
'Ceiling beings to sink upon entry',
'Archways lead to other places',
'Sculptures of gargoyles tearing apart angels',
'The walls are covered in mirrors that reflect nothing',
'Rotates faster and faster once entered',
'Gargoyles along ceiling shout abuse',
'Randomly dimension doors those that cross it',
'Altar attacks characters with magic',
'Has a magic pool',
'Is the stomach of a creature',
'Weeps to itself',
'Is alive'
];
export default exoticRoomCharacteristics;